.br_procedure_badge {
  width: 20px;
  background-color: #a54b1a;
  color: white;
  position: absolute;
}

.br_procedure_sliderImage {
  flex: 1;
  object-fit: cover;
  height: 100px;
  border-radius: 4px;
  width: -webkit-fill-available;
}

.br_procedure_sliderRow {
  margin-top: -31px;
}

.br_procedure_sliderCol {
  margin-right: 0.3rem;
}

.br_procedure_sliderText {
  overflow: hidden;
  height: 46px;
  margin: 0px;
  padding: 0px 3px;
}

.br_procedure_container {
  width: 200px;
  display: flex;
}

.br_procedure_slide {
  overflow-x: auto;
  overflow-y: hidden;
}